import React from "react";
import "./Mobilemenu.css";

function Mobilemenu({ isMenuOpen, toggleMenu }) {
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="mobile-menu">
      <ul className={`navbar ${isMenuOpen ? "open" : ""}`}>
        <li onClick={() => scrollToSection("spotlight")}>Home</li>
        <li onClick={() => scrollToSection("service")}>Services</li>
        <li onClick={() => scrollToSection("work")}>Projects</li>
        <li onClick={() => scrollToSection("footer")}>Contact Us</li>
      </ul>
    </div>
  );
}

export default Mobilemenu;
