import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Components/Home/Home";
import ProjectList from "./Components/ProjectList/ProjectList";
import AnimateCursor from "./Components/AnimateCursor/AnimateCursor";
import "./App.css";
import Lottie from "react-lottie";
import animationData from "./Assets/lotties/yyhWaRPrJd.json";
import Privacy from "./Components/Privacy/Privacy";
import Terms from "./Components/Terms/Terms";

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className="App">
      {loading ? (
        <div className="loader-container">
          <Lottie options={defaultOptions} height={300} width={300} />
        </div>
      ) : (
        <>
          <AnimateCursor />
          <Router>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/projects" element={<ProjectList />} />
              <Route path="/privacy" element={<Privacy />} />
              <Route path="/terms" element={<Terms />} />
            </Routes>
          </Router>
        </>
      )}
    </div>
  );
}

export default App;
